export default {
    data() {
        return {
            dates: '',
        }
    },
    methods: {
        setDates(dates) {
            const newDates = dates.split("/");
            if (dates) {
                const valDates = `${newDates[0]}/${newDates[1]}/${newDates[2]}`;
                const [dd, mm, yyyy] = valDates.split("/");
                return new Date(`${dd}-${mm}-${yyyy}`);
            }
            return "";
        },
        formatDate(dates, order) {
            if (dates) {
                const month = this.$DATEFORMAT(new Date(dates), "MM");
                const day = this.$DATEFORMAT(new Date(dates), "dd");
                const year = this.$DATEFORMAT(new Date(dates), "yyyy");

                switch (order) {
                    case 'arrange':
                        return `${year}-${month}-${day}`;
                    default:
                        return `${month}/${day}/${year}`;
                }
            }
            return "";
        },
        formatDateTime(dates) {
            const newDates = dates.split("/");
            if (dates) {
                const valDates = `${newDates[0]}/${newDates[1]}/${newDates[2]}`;
                const [dd, mm, yyyy] = valDates.split("/");
                const isoDate = new Date(`${dd}-${mm}-${yyyy}`).toISOString();
                return this.$DATEFORMAT(new Date(isoDate), "MMMM dd, yyyy");
            }
            return "";
        }
    }
};