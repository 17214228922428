<template>
  <div class="flex flex-col mb-3">
    <div style="height: 100%" class="mt-20" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div v-else>
      <div
        class="w-full flex flex-col items-center"
        v-if="comments.length === 0"
      >
        <icon icon-name="empty_comments" size="xl" style="width: 450" />
      </div>

        <div class="h-full w-full" v-else>
      <scroll-container height="450px" style="width: 100%; overflow-x: hidden">
        <div
          class="w-full flex flex-col mb-2 p-2"
          v-for="(item, index) in comments"
          :key="index"
        >
          <div class="w-full flex">
            <div class="flex">
              <img
                class="mr-3"
                :src="item.sender.photo"
                v-if="item.sender.photo"
                alt="user photo"
                style="height: 40px; width: 40px; border-radius: 5px"
              />
              <div
                style="height: 40px; width: 40px; border-radius: 5px"
                class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold pt-1"
                v-else
              >
                {{ $getInitials(`${item.sender.fname} ${item.sender.lname}`) }}
              </div>
            </div>
            <div
              class="flex w-full flex-col whitespace-nowrap font-semibold text-sm leading-5"
            >
              <div class="flex w-full ml-2">
                <div class="flex flex-grow">
                  <span class="text-darkPurple capitalize">
                    {{ `${item.sender.fname} ${item.sender.lname}` }}
                  </span>
                  <span
                    class="text-xs font-semibold ml-1 flex text-romanSilver uppercse"
                    style="line-height: 1.8"
                  >
                    {{ item.date }}
                  </span>
                  <span
                    class="text-xs font-semibold ml-1 flex text-romanSilver uppercse"
                    style="line-height: 1.8"
                  >
                    ({{ item.time }})
                  </span>
                </div>
              </div>
              <span class="flex text-xs font-semibold ml-2 text-romanSilver">
                {{ item.sender.designation }}
              </span>
            </div>
          </div>

          <div class="my-2 w-full flex p-1">
            <div class="flex flex-grow">
              <span class="text-base">
                {{ item.message }}
              </span>
            </div>
            <div class="flex">
              <icon icon-name="icon-trash" size="xs" />
            </div>
          </div>
        </div>
        </scroll-container>
        </div>

        </div>
  </div>
</template>

<script>
import ScrollContainer from "./ScrollContainer";

export default {
  name: "CommentsBox",
  components: {
    ScrollContainer
  },

  props: {
    comments: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    markAsRead(commentId) {
      this.$emit("markRead", commentId);
    },
  },
};
</script>
