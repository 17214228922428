<template>
  <div
    class="relative transition-all duration-1000"
    :class="`${topDivStyles}`"
    :style="
      showBorder
        ? 'box-shadow: 0px 1px 2px rgba(45, 49, 66, 0.06), 0px 1px 3px rgba(8, 7, 8, 0.1); border-radius: 5px;'
        : ''
    "
  >
    <div class="flex items-center justify-between">
      <div class="w-auto flex" v-if="hasDropdownTitleSlot">
        <slot name="title" />
        <slot name="editIcon" />
      </div>

      <divider class="flex-grow w-auto mx-3" v-if="showDivider" border-dash />

      <slot name="block" />

      <div
        v-if="secondary"
        @click="handleShow()"
        :class="[
          'text-sm font-semibold flex items-center cursor-pointer',
          show ? 'text-flame' : ''
        ]"
      >
        <Icon
          icon-name="chevronDown"
          class="cursor-pointer"
          :class-name="show ? 'transformIconTwo' : 'cursor-pointer'"
        />
        {{ show ? "Collapse" : "Expand" }}
      </div>
      <div
        v-else
        @click="handleShow()"
        class="text-sm font-semibold flex items-center"
      >
        <Icon
          icon-name="icon-chevron-down"
          class="cursor-pointer mr-3"
          :class-name="show ? 'transformIcon' : 'cursor-pointer'"
        />
      </div>
    </div>
    <div
      class=" pb-5 pt-3 mt-4"
      :style="{ 'border-top: 1px solid #878E99;': showDivider }"
      v-if="handleOpen"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import Divider from "./divider";

export default {
  components: { Divider },
  props: {
    showDivider: {
      type: Boolean,
      default: false
    },
    showBorder: {
      type: Boolean,
      default: false
    },
    isOpen: {
      type: Boolean
    },
    topDivStyles: {
      type: String,
      default: "my-4 py-3"
    },
    secondary: {
      type: Boolean
    },
    openedIndex: {
      type: [Number, Boolean],
      default: 0
    },
    openOneOnly: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      show: this.isOpen || false
    };
  },
  computed: {
    hasDropdownTitleSlot() {
      return !!this.$slots.title;
    },
    handleOpen() {
      if (this.openOneOnly) {
        return this.index === this.openedIndex;
      }
      return this.show;
    }
  },
  watch: {
    isOpen() {
      if (this.isOpen) {
        this.handleShow();
      }
    }
  },
  methods: {
    handleShow() {
      if (this.openOneOnly) {
        const value = this.index === this.openedIndex ? false : this.index;
        return this.$emit("open", value);
      }
      this.show = !this.show;
      return this.$emit("open", this.show);
    }
  }
};
</script>

<style>
.transformIcon {
  transform: rotate(90deg);
  cursor: pointer;
}
.transformIconTwo {
  transform: rotate(180deg);
  cursor: pointer;
}
</style>
